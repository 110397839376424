import * as React from 'react';
import { useForm, useFormState, useFormContext, useWatch, useFieldArray, Controller } from 'react-hook-form';
import { Box, Button, Typography, useMediaQuery, TextField as MuiTextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    DateTimeInput,
    PasswordInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    useRecordContext,
    Loading,
    useGetIdentity,
    useCreate,
    useRedirect,
    useUpdate,
    useNotify,
    Toolbar,
    SaveButton,
    DeleteButton,
    Form,
    useRefresh
} from 'react-admin';
import { formatDate, getValidationErrors, uploadImageToFirebaseStorage } from '../utils/util';
import { Consts } from '../consts/Const';
import { display } from '@mui/system';
import { useState } from 'react';
import { AddAlarmSharp, OpenInNewOffOutlined } from '@mui/icons-material';
import { CustomForm } from './RankingFormComponents';
import Error from '../Error';
import { useLocation } from 'react-router';

const PostEditToolbar = (props: any) => {
    const { isSubmitting, ...rest } = props;
    const redirect = useRedirect();
    const notify = useNotify();
    return (
        <Toolbar {...rest} sx={{ justifyContent: 'space-between' }}>
            <SaveButton disabled={isSubmitting} label="保存" />
            <DeleteButton />
            {/* <SaveButton
                label="post.action.save_and_add"
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.created', {
                            type: 'info',
                            messageArgs: { smart_count: 1 },
                        });
                        redirect(false);
                    }}
                }
                type="button"
                variant="text"
            /> */}
        </Toolbar>
    );
};

const RankingEditContent = ({ currentUser, onSubmit, isSubmitting }: any) => {
    const record = useRecordContext();

    if (!record) return <Loading />;

    // 権限別調整
    let isNotEditPermitted;
    let isNotPagePermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            isNotEditPermitted = record.areaId !== currentUser.areaId;
            break;
        case Consts.AUTORITY.mall:
            isNotPagePermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPagePermitted = true;
            break;
        default:
            break;
    }

    if (isNotPagePermitted) return <Error type="permission" />;

    return (
        <CustomForm
            onSubmit={onSubmit}
            isNotEditPermitted={isNotEditPermitted}
            currentUser={currentUser}
            toolbar={<PostEditToolbar isSubmitting={isSubmitting} />}
        />
    );
};

export const RankingEdit = () => {
    const { identity: currentUser, isLoading } = useGetIdentity();
    const [update] = useUpdate();
    const redirect = useRedirect();
    const location = useLocation();
    const notify = useNotify();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const refresh = useRefresh();
    const onSubmit = async (data: any) => {
        setIsSubmitting(true);
        try {
            await update(
                'rankings',
                {
                    id: data.id,
                    data: {
                        isValidationOnly: true,
                        ...data
                    }
                },
                { returnPromise: true }
            );
        } catch (error: any) {
            setIsSubmitting(false);
            const validationErrors = getValidationErrors(error);
            return validationErrors;
        }

        // formの送信
        try {
            await update('rankings', { id: data.id, data: data }, { returnPromise: true });
            notify('resources.contests.notification.updated_success', { type: 'success' });
            refresh();
        } catch (error: any) {
            setIsSubmitting(false);
            const validationErrors = getValidationErrors(error);
            return validationErrors;
        }

        setIsSubmitting(false);
    };

    const onChange = (data: any) => {
        data ? redirect(`/rankings/${data}`) : null;
        return;
    };

    if (isLoading) return <Loading />;

    return (
        <>
            <Form>
                <ReferenceInput source="areaId" reference="areas">
                    <SelectInput
                        label="設定地区"
                        emptyValue={false}
                        optionText="areaName"
                        defaultValue={location.pathname.replace(/\/$/, '').split('/').slice(-1)[0]}
                        onChange={onChange}
                    />
                </ReferenceInput>
            </Form>
            <Edit
                title="ランキング設定"
                sx={{
                    padding: {
                        // xs: '20px 10px',
                        sm: '0px 0px 20px'
                    }
                }}
            >
                <RankingEditContent currentUser={currentUser} onSubmit={onSubmit} isSubmitting={isSubmitting} />
            </Edit>
        </>
    );
};
