import * as React from 'react';
import { Box, Button as MuiButton, Typography, useMediaQuery, TextField as MuiTextField } from '@mui/material';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    UrlField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useRecordContext,
    useDataProvider,
    Loading,
    DateTimeInput,
    PasswordInput,
    useGetIdentity,
    Pagination,
    useCreate,
    useUpdate,
    useRedirect,
    useNotify,
    downloadCSV,
    Toolbar,
    SaveButton,
    BulkDeleteButton,
    DeleteWithConfirmButton,
    useRefresh,
    useUnselectAll,
    useDeleteMany,
    Button,
    Confirm,
    useListContext,
    NumberInput
} from 'react-admin';
import { Consts } from '../consts/Const';
import { useState } from 'react';
import { Fragment } from 'react';
import { formatDate, getValidationErrors } from '../utils/util';
import CustomBreadcrumbs from '../layout/Breadcrumbs';
import Error from '../Error';
import DeleteIcon from '@mui/icons-material/Delete';
import jsonExport from 'jsonexport/dist';

const adminFilters: any[] = [
    // [TODO] 未実装のため全文検索コメントアウト
    // <TextInput source="q" label="Search" alwaysOn />,
    // <ReferenceInput source="userId" label="User" reference="users" allowEmpty>
    //     <SelectInput optionText="name" />
    // </ReferenceInput>,
];

let isNotEditPermitted = false;

const PostPagination = () => <Pagination rowsPerPageOptions={[25, 50, 100]} />;

const CustomDeleteButton = () => {
    const datas: any = useListContext();
    const [open, setOpen] = useState(false);
    const [deleteMany] = useDeleteMany();
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll(datas.resource);

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = async () => {
        const data: any = datas;
        data['ids'] = datas.selectedIds;
        try {
            await deleteMany('admins', data);
            refresh();
            unselectAll();
            notify('削除完了しました', { type: 'success' });
        } catch (error: any) {
            notify('削除失敗しました');
        }
        setOpen(false);
    };

    return (
        <Fragment>
            <Button
                label="削除"
                onClick={handleClick}
                sx={{
                    color: 'rgb(211, 47, 47)'
                }}
            >
                <DeleteIcon />
            </Button>
            <Confirm
                isOpen={open}
                // loading={isLoading}
                title="削除確認"
                content="ユーザーを削除してもよろしいですか？"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export const AdminList = () => {
    const { identity: currentUser, isLoading } = useGetIdentity();

    const exporter = (data: any) => {
        // BOM付きで出力
        const BOM = '\uFEFF';
        jsonExport(data, (err: any, csv: any) => {
            console.log(csv);
            downloadCSV(`${BOM} ${csv}`, 'admins');
            if (err) {
                console.log('Error trying to export list');
            }
        });
    };

    if (isLoading) return <Loading />;
    if (!currentUser) return <Error />;

    // 権限別調整
    let filter = {};
    let sortable = true;
    let isNotPagePermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            filter = { areaId: currentUser.areaId };
            sortable = false;
            break;
        case Consts.AUTORITY.mall:
            isNotPagePermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPagePermitted = true;
            break;
        default:
            break;
    }

    if (isNotPagePermitted) return <Error type="permission" />;

    return (
        <List
            perPage={50}
            pagination={<PostPagination />}
            filters={adminFilters}
            exporter={exporter}
            filter={{ authority_ne: 3, ...filter }}
            title="ユーザー一覧"
        >
            <Datagrid rowClick="edit" bulkActionButtons={<CustomDeleteButton />}>
                <TextField label="管理ユーザーID" source="id" />
                <TextField label="名前" source="name" />
                <FunctionField
                    label="権限"
                    sortBy="authority"
                    render={(record: any) => Consts.authorities[record.authority]}
                />
                <ReferenceField label="所属地区" source="areaId" reference="areas" link={false} sortable={sortable}>
                    <TextField source="areaName" />
                </ReferenceField>
                <FunctionField
                    label="更新日"
                    sortBy="updatedAt"
                    render={(record: any) => `${formatDate(new Date(record.updatedAt), Consts.dateFormat)}`}
                />
                <EditButton />
            </Datagrid>
        </List>
    );
};

const AdminEditContext = (props: any) => {
    const record = useRecordContext();

    // 権限別調整
    let isNotPagePermitted;
    switch (props.currentUser.authority) {
        case Consts.AUTORITY.area:
            isNotPagePermitted = props.currentUser.areaId !== record.areaId;
            break;
        case Consts.AUTORITY.mall:
            isNotPagePermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPagePermitted = true;
            break;
        default:
            break;
    }

    if (isNotPagePermitted) return <Error type="permission" />;

    // 編集権調整
    if (props.currentUser.authority == Consts.AUTORITY.area) {
        if (props.currentUser.id != record.id) {
            isNotEditPermitted = true;
        } else {
            isNotEditPermitted = false;
        }
    }

    // 子要素に props を引き継ぐ
    const childrenWithProps = React.Children.map(props.children, (child) => {
        return React.cloneElement(child, { record, ...props });
    });

    return <>{childrenWithProps}</>;
};

export const AdminEdit = (props: any) => {
    const [update] = useUpdate();
    const redirect = useRedirect();
    const notify = useNotify();
    const { identity: currentUser, isLoading } = useGetIdentity();
    const validateAdminForm = async (values: any) => {
        const errors: any = {};
        if (!values.name) {
            errors.name = '必須です';
        }
        if (!values.loginId) {
            errors.loginId = '必須です';
        }
        if (!values.authority && values.authority !== 0) {
            errors.authority = '必須です';
        }
        if (values.password) {
            if (values.password.length < 6) {
                errors.password = '6文字以上でなければなりません';
            }
        }
        if (values.passwordConfirm) {
            if (values.passwordConfirm.length < 6) {
                errors.passwordConfirm = '6文字以上でなければなりません';
            }
        }
        if (values.passwordConfirm !== values.password) {
            errors.passwordConfirm = 'パスワードが一致しません';
        }

        return errors;
    };
    const CustomToolbar = (props: any) => (
        <Toolbar {...props} sx={{ justifyContent: 'space-between' }}>
            <SaveButton />
            <DeleteWithConfirmButton confirmContent="ユーザーを削除してもよろしいですか？" confirmTitle="削除確認" />
        </Toolbar>
    );

    if (isLoading) return <Loading />;
    if (!currentUser) return <Error />;

    // 権限別調整
    let isNotPagePermitted;
    let isPermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            break;
        case Consts.AUTORITY.mall:
            isNotPagePermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPagePermitted = true;
            break;
        default:
            isPermitted = true;
            break;
    }

    if (isNotPagePermitted) return <Error type="permission" />;

    const handleSubmit = async (data: any) => {
        try {
            await update('admins', { id: data.id, data: data }, { returnPromise: true });
            notify('更新完了しました', { type: 'success' });
            redirect('/admins');
        } catch (error: any) {
            const validationErrors = getValidationErrors(error);
            return validationErrors;
        }
    };

    return (
        <Box sx={{ paddingBottom: '50px' }}>
            <CustomBreadcrumbs
                breadcrumbs={[
                    { url: '/admins', text: 'ユーザー一覧' },
                    { url: null, text: 'ユーザー編集' }
                ]}
            />
            <Edit title="ユーザー編集" {...props}>
                <AdminEditContext currentUser={currentUser} {...props}>
                    <AdminForm
                        currentUser={currentUser}
                        isPermitted={isPermitted}
                        validate={validateAdminForm}
                        isRequired={false}
                        onSubmit={handleSubmit}
                        toolbar={<CustomToolbar />}
                    />
                </AdminEditContext>
            </Edit>
        </Box>
    );
};

export const AdminCreate = (props: any) => {
    const [create] = useCreate();
    const redirect = useRedirect();
    const notify = useNotify();
    const { identity: currentUser, isLoading } = useGetIdentity();
    const validateAdminForm = async (values: any) => {
        const errors: any = {};
        if (!values.name) {
            errors.name = '必須です';
        }
        if (!values.loginId) {
            errors.loginId = '必須です';
        }
        if (!values.password) {
            errors.password = '必須です';
        }
        if (!values.passwordConfirm) {
            errors.passwordConfirm = '必須です';
        }
        if (!values.authority && values.authority !== 0) {
            errors.authority = '必須です';
        }
        if (values.password) {
            if (values.password.length < 6) {
                errors.password = '6文字以上でなければなりません';
            }
        }
        if (values.passwordConfirm) {
            if (values.passwordConfirm.length < 6) {
                errors.passwordConfirm = '6文字以上でなければなりません';
            }
        }
        if (values.passwordConfirm !== values.password) {
            errors.passwordConfirm = 'パスワードが一致しません';
        }

        return errors;
    };

    if (isLoading) return <Loading />;
    if (!currentUser) return <Error />;

    // 権限別調整
    let isNotPagePermitted;
    let isPermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            break;
        case Consts.AUTORITY.mall:
            isNotPagePermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPagePermitted = true;
            break;
        default:
            isPermitted = true;
            break;
    }

    if (isNotPagePermitted) return <Error type="permission" />;

    const handleSubmit = async (data: any) => {
        try {
            await create('admins', { data: data }, { returnPromise: true });
            notify('登録完了しました', { type: 'success' });
            redirect('/admins');
        } catch (error: any) {
            const validationErrors = getValidationErrors(error);
            return validationErrors;
        }
    };

    return (
        <Box sx={{ paddingBottom: '50px' }}>
            <CustomBreadcrumbs
                breadcrumbs={[
                    { url: '/admins', text: 'ユーザー一覧' },
                    { url: null, text: `ユーザー新規登録` }
                ]}
            />
            <Create title="ユーザー登録" {...props}>
                <AdminForm
                    currentUser={currentUser}
                    isPermitted={isPermitted}
                    validate={validateAdminForm}
                    isRequired={true}
                    disabled={isNotEditPermitted}
                    onSubmit={handleSubmit}
                />
            </Create>
        </Box>
    );
};

export const AdminForm = (props: any) => {
    const { isPermitted, record, ...rest } = props;

    const authorityChoises = isPermitted
        ? [
              { id: Consts.AUTORITY.system, name: 'システム管理者' },
              { id: Consts.AUTORITY.integrate, name: '同友店統括部' },
              { id: Consts.AUTORITY.area, name: '地区担当者' },
              // SCは本画面では選択できない。SC画面で管理アカウント・投稿アカウント両方を取り扱うため
              // { id: Consts.AUTORITY.mall, name: 'SC' },
              { id: Consts.AUTORITY.maker, name: '審査関係者' }
          ]
        : [
              { id: Consts.AUTORITY.area, name: '地区担当者' },
              { id: Consts.AUTORITY.maker, name: '審査関係者' }
          ];

    if (record && record.authority === Consts.AUTORITY.mall) {
        authorityChoises[authorityChoises.length] = { id: Consts.AUTORITY.mall, name: 'SC' };
    }

    return (
        <SimpleForm validate={props.validate} onSubmit={props.onSubmit} toolbar={props.toolbar}>
            <TextInput label="管理ユーザーID" source="id" disabled fullWidth isRequired />
            <TextInput label="名前" source="name" fullWidth isRequired disabled={isNotEditPermitted} />
            <ReferenceInput source="areaId" reference="areas">
                <SelectInput
                    label="所属地区"
                    optionText="areaName"
                    fullWidth
                    disabled={props.currentUser.areaId ? true : false}
                    defaultValue={props.currentUser.areaId ? props.currentUser.areaId : undefined}
                />
            </ReferenceInput>
            <TextInput
                label="ログインId"
                source="loginId"
                fullWidth
                isRequired
                disabled={props.currentUser.areaId && !props.isRequired ? true : false}
            />
            <PasswordInput
                label="パスワード"
                source="password"
                isRequired={props.isRequired}
                disabled={isNotEditPermitted}
            />
            <PasswordInput
                label="パスワード(確認)"
                source="passwordConfirm"
                isRequired={props.isRequired}
                disabled={isNotEditPermitted}
            />
            <SelectInput
                label={'権限'}
                source={`authority`}
                choices={authorityChoises}
                isRequired
                disabled={
                    (!!record && record.authority === Consts.AUTORITY.mall) ||
                    (props.currentUser.areaId && !props.isRequired ? true : false)
                }
            />
            <DateTimeInput label="作成日時" source="createdAt" disabled isRequired />
            <DateTimeInput label="更新日時" source="updatedAt" disabled isRequired />
        </SimpleForm>
    );
};

const AdminTitle = ({ record }: any) => {
    return <span>Admin Edit {record ? `"${record.title}"` : ''}</span>;
};
