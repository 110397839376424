import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    CreateBase,
    FileField,
    FileInput,
    Form,
    ImageField,
    ImageInput,
    PasswordInput,
    SaveButton,
    useUpdate
} from 'react-admin';
import { Box } from '@mui/material';
import axios from '../axios/axios';

export default function ChangePasswordFormDialog({ currentUser }: any) {
    const [update, { isLoading: isUseUpdateLoading, error: useUpdateError }] = useUpdate();
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isCompleted, setIsCompleted] = React.useState(false);
    const [error, setError] = React.useState(false);

    const handleClickOpen = () => {
        setIsLoading(false);
        setError(false);
        setIsCompleted(false);
        setOpen(true);
    };

    const handleClose = (event?: object, reason?: string) => {
        if (isLoading && reason == 'backdropClick') {
            return;
        }
        setOpen(false);
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        await axios
            .patch(`/admins/${currentUser.id}`, data)
            .then((data) => {
                setIsLoading(false);
                setIsCompleted(true);
            })
            .catch((error) => {
                setIsLoading(false);
                setIsCompleted(true);
                setError(true);
            });
    };

    const validatePasswardChange = async (values: any) => {
        const errors: any = {};
        if (!values.password) {
            errors.password = '入力してください';
        } else if (!values.password.match(/^([a-zA-Z0-9]{6,})$/)) {
            errors.password = 'パスワードは6文字以上の英数字で入力してください';
        }
        if (!values.passwordConfirm) {
            errors.passwordConfirm = '入力してください';
        }
        if (values.password !== values.passwordConfirm) {
            errors.password = 'パスワードが一致していません';
            errors.passwordConfirm = 'パスワードが一致していません';
        }

        return errors;
    };

    return (
        <Box sx={{ display: 'inline-block' }}>
            <Button variant="outlined" onClick={handleClickOpen} sx={{ margin: '1em' }}>
                パスワードを変更する
            </Button>
            <Dialog
                open={open}
                onClose={(event: object, reason: string) => handleClose(event, reason)}
                disableEscapeKeyDown={true}
            >
                {isCompleted ? (
                    <>
                        <DialogTitle>パスワード変更{error ? '失敗' : '完了'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                パスワードの変更が{error ? '失敗' : '完了'}しました。
                                {error ? (
                                    <>
                                        <br />
                                        お手数ですが、もう一度実行するか、管理者へお問合せください。
                                    </>
                                ) : null}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>閉じる</Button>
                        </DialogActions>
                    </>
                ) : (
                    <>
                        <DialogTitle>パスワード変更</DialogTitle>
                        <Form onSubmit={onSubmit} validate={validatePasswardChange}>
                            <DialogContent>
                                <Box>
                                    <PasswordInput source="password" label="パスワード" />
                                </Box>
                                <Box>
                                    <PasswordInput source="passwordConfirm" label="パスワード確認" />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} disabled={isLoading}>
                                    キャンセル
                                </Button>
                                <SaveButton disabled={isLoading || isUseUpdateLoading}>登録する</SaveButton>
                            </DialogActions>
                        </Form>
                    </>
                )}
            </Dialog>
        </Box>
    );
}
