import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    UrlField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    NumberInput,
    FunctionField,
    DateTimeInput,
    TopToolbar,
    FilterButton,
    ExportButton,
    Button,
    Link,
    useRecordContext,
    CreateButton,
    PasswordInput,
    useGetOne,
    Loading,
    useDataProvider,
    useGetIdentity,
    downloadCSV,
    Toolbar,
    SaveButton,
    Pagination,
    useCreate,
    useUpdate,
    useRedirect,
    useNotify,
    DeleteWithConfirmButton,
    useRefresh,
    useUnselectAll,
    useDeleteMany,
    Confirm,
    useListContext
} from 'react-admin';
import { Box, Typography, useMediaQuery, TextField as MuiTextField, Grid } from '@mui/material';
import { Consts } from '../consts/Const';
import { formatDate, getValidationErrors } from '../utils/util';
import MyUrlField from './MyUrlField';
import IconEvent from '@mui/icons-material/Event';
import { stringify } from 'query-string';
import FormDialog from './FormDialog';
import CustomBreadcrumbs from '../layout/Breadcrumbs';
import Error from '../Error';
import jsonExport from 'jsonexport/dist';
import { useState, Fragment } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

const mallFilters: any[] = [
    // [TODO] 未実装のため全文検索コメントアウト
    // <TextInput source="q" label="Search" alwaysOn />,
    // <ReferenceInput source="id" label="SC" reference="malls" allowEmpty>
    //     <SelectInput optionText="mallName" />
    // </ReferenceInput>,
];

// const csvDownload = function(data: any, filename: string) {
//     // UTF BOM
//     var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
//     // リンククリエイト
//     var downloadLink = document.createElement("a");
//     downloadLink.download = filename + ".csv";
//     // ファイル情報設定
//     downloadLink.href = URL.createObjectURL(new Blob([bom, data], { type: "text/csv" }));
//     downloadLink.dataset.downloadurl = ["text/csv", downloadLink.download, downloadLink.href].join(":");
//     // イベント実行
//     downloadLink.click();
// }

const PostPagination = () => <Pagination rowsPerPageOptions={[25, 50, 100]} />;
const CustomDeleteButton = () => {
    const datas: any = useListContext();
    const [open, setOpen] = useState(false);
    const [deleteMany] = useDeleteMany();
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll(datas.resource);

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = async () => {
        const data: any = datas;
        data['ids'] = datas.selectedIds;
        try {
            await deleteMany('malls', data);
            refresh();
            unselectAll();
            notify('削除完了しました', { type: 'success' });
        } catch (error: any) {
            notify('削除失敗しました');
        }
        setOpen(false);
    };

    return (
        <Fragment>
            <Button
                label="削除"
                onClick={handleClick}
                sx={{
                    color: 'rgb(211, 47, 47)'
                }}
            >
                <DeleteIcon />
            </Button>
            <Confirm
                isOpen={open}
                // loading={isLoading}
                title="削除確認"
                content="SCを削除してもよろしいですか？"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};
export const MallList = (props: any) => {
    const { identity: currentUser, isLoading } = useGetIdentity();

    const exporter = (data: any) => {
        for (let index = 0; index < data.length; index++) {
            const item = data[index];
            // カラム名を一括インポート用に変換（API側と逆）
            Object.keys(data[index]).map((key) => {
                switch (key) {
                    case 'id':
                        item['SCID'] = item[key];
                        break;
                    case 'numberOfTenants':
                        item['店舗数'] = item[key];
                        break;
                    case 'loginId':
                        item['同友店会ID'] = item[key];
                        break;
                    case 'mallName':
                        item['SC名'] = item[key];
                        break;
                    case 'mallNameKana':
                        item['SC名カナ'] = item[key];
                        break;
                    case 'areaName':
                        item['地区名'] = item[key];
                        break;
                    case 'adminLoginId':
                        item['管理画面ログインID'] = item[key];
                        break;
                    case 'password':
                        item['パスワード'] = item[key];
                        break;
                    default:
                        break;
                }
                delete item[key];
            });
            item['パスワード'] = '';
        }

        // BOM付きで出力
        const BOM = '\uFEFF';
        jsonExport(data, (err: any, csv: any) => {
            downloadCSV(`${BOM}${csv}`, 'malls');
            if (err) {
                console.log('Error trying to export list');
            }
        });
    };

    if (isLoading) return <Loading />;
    if (!currentUser) return <Error />;

    let Actions = () => (
        <TopToolbar>
            <ExportButton />
            <CreateButton />
            <FormDialog />
        </TopToolbar>
    );

    // 権限別調整
    let filter = {};
    let sortable = true;
    let isNotPagePermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            filter = { areaId: currentUser.areaId };
            sortable = false;
            Actions = function Actions() {
                return (
                    <TopToolbar>
                        <ExportButton />
                    </TopToolbar>
                );
            };
            break;
        case Consts.AUTORITY.mall:
            isNotPagePermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPagePermitted = true;
            break;
        default:
            break;
    }

    if (isNotPagePermitted) return <Error type="permission" />;

    return (
        <List
            perPage={50}
            pagination={<PostPagination />}
            {...props}
            filters={mallFilters}
            actions={<Actions />}
            filter={filter}
            exporter={exporter}
            title="SC一覧"
        >
            {/* <Datagrid rowClick="edit" bulkActionButtons={<CustomDeleteButton />}> */}
            <Datagrid rowClick="edit" bulkActionButtons={false}>
                <TextField label="SC番号" source="id" />
                <TextField label="SC名" source="mallName" />
                <ReferenceField label="所属地区" source="areaId" reference="areas" link={false} sortable={sortable}>
                    <TextField source="areaName" />
                </ReferenceField>
                <ReferenceField label="管理者ログインID" source="adminId" reference="admins">
                    <TextField source="loginId" />
                </ReferenceField>
                <TextField label="同友店会ID" source="loginId" />
                <FunctionField
                    label="更新日"
                    sortBy="updatedAt"
                    render={(record: any) => `${formatDate(new Date(record.updatedAt), Consts.dateFormat)}`}
                />
                <EditButton />
            </Datagrid>
        </List>
    );
};

const MallTitle = ({ record }: any) => {
    return <span>User Edit {record ? `"${record.title}"` : ''}</span>;
};

const MallEditContext = (props: any) => {
    const record = useRecordContext();

    // 権限別調整
    let isNotPagePermitted;
    switch (props.currentUser.authority) {
        case Consts.AUTORITY.area:
            isNotPagePermitted = props.currentUser.areaId !== record.areaId;
            break;
        case Consts.AUTORITY.mall:
            isNotPagePermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPagePermitted = true;
            break;
        default:
            break;
    }

    if (isNotPagePermitted) return <Error type="permission" />;

    return <>{props.children}</>;
};

export const MallEdit = (props: any) => {
    const [update] = useUpdate();
    const redirect = useRedirect();
    const notify = useNotify();
    const { identity: currentUser, isLoading } = useGetIdentity();
    const validateMallForm = async (values: any) => {
        const errors: any = {};
        if (!values.mallName) {
            errors.mallName = '必須です';
        }
        if (!values.mallNameKana) {
            errors.mallNameKana = '必須です';
        }
        if (!values.areaId) {
            errors.areaId = '必須です';
        }
        if (!values.adminLoginId) {
            errors.adminLoginId = '必須です';
        }
        if (!values.loginId) {
            errors.loginId = '必須です';
        }
        if (!values.numberOfTenants) {
            errors.numberOfTenants = '必須です';
        }
        if (values.password) {
            if (values.password.length < 6) {
                errors.password = '6文字以上でなければなりません';
            }
        }
        if (values.passwordConfirm) {
            if (values.passwordConfirm.length < 6) {
                errors.passwordConfirm = '6文字以上でなければなりません';
            }
        }
        if (values.passwordConfirm !== values.password) {
            errors.passwordConfirm = 'パスワードが一致しません';
        }

        return errors;
    };
    const EditToolbar = () => (
        <Toolbar sx={{ justifyContent: 'space-between' }}>
            <SaveButton />
            <DeleteWithConfirmButton confirmContent="SCを削除してもよろしいですか？" confirmTitle="削除確認" />
        </Toolbar>
    );
    const EditToolbarForArea = () => (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );

    if (isLoading) return <Loading />;
    if (!currentUser) return <Error />;

    // 権限別調整
    let isNotPagePermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            break;
        case Consts.AUTORITY.mall:
            isNotPagePermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPagePermitted = true;
            break;
        default:
            break;
    }

    if (isNotPagePermitted) return <Error type="permission" />;

    const handleSubmit = async (data: any) => {
        try {
            await update('malls', { id: data.id, data: data }, { returnPromise: true });
            notify('更新完了しました', { type: 'success' });
            redirect('/malls');
        } catch (error: any) {
            const validationErrors = getValidationErrors(error);
            return validationErrors;
        }
    };

    return (
        <Box sx={{ paddingBottom: '50px' }}>
            <CustomBreadcrumbs
                breadcrumbs={[
                    { url: '/malls', text: 'SC一覧' },
                    { url: null, text: 'SC編集' }
                ]}
            />
            <Edit title="SC編集" {...props}>
                <MallEditContext currentUser={currentUser} {...props}>
                    <MallForm
                        currentUser={currentUser}
                        validate={validateMallForm}
                        isRequired={false}
                        disabled={true}
                        onSubmit={handleSubmit}
                        toolbar={
                            currentUser.authority === Consts.AUTORITY.area ? <EditToolbarForArea /> : <EditToolbar />
                        }
                    />
                </MallEditContext>
            </Edit>
        </Box>
    );
};

export const MallCreate = (props: any) => {
    const [create] = useCreate();
    const redirect = useRedirect();
    const notify = useNotify();
    const { identity: currentUser, isLoading } = useGetIdentity();
    const validateMallForm = async (values: any) => {
        const errors: any = {};
        if (!values.mallName) {
            errors.mallName = '必須です';
        }
        if (!values.mallNameKana) {
            errors.mallNameKana = '必須です';
        }
        if (!values.areaId) {
            errors.areaId = '必須です';
        }
        if (!values.adminLoginId) {
            errors.adminLoginId = '必須です';
        }
        if (!values.password) {
            errors.password = '必須です';
        }
        if (!values.passwordConfirm) {
            errors.passwordConfirm = '必須です';
        }
        if (!values.loginId) {
            errors.loginId = '必須です';
        }
        if (!values.numberOfTenants) {
            errors.numberOfTenants = '必須です';
        }
        if (values.password) {
            if (values.password.length < 6) {
                errors.password = '6文字以上でなければなりません';
            }
        }
        if (values.passwordConfirm) {
            if (values.passwordConfirm.length < 6) {
                errors.passwordConfirm = '6文字以上でなければなりません';
            }
        }
        if (values.passwordConfirm !== values.password) {
            errors.passwordConfirm = 'パスワードが一致しません';
        }

        return errors;
    };

    if (isLoading) return <Loading />;
    if (!currentUser) return <Error />;

    // 権限別調整
    let isNotPagePermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            break;
        case Consts.AUTORITY.mall:
            isNotPagePermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPagePermitted = true;
            break;
        default:
            break;
    }

    if (isNotPagePermitted) return <Error type="permission" />;

    const handleSubmit = async (data: any) => {
        try {
            await create('malls', { data: data }, { returnPromise: true });
            notify('登録完了しました', { type: 'success' });
            redirect('/malls');
        } catch (error: any) {
            const validationErrors = getValidationErrors(error);
            return validationErrors;
        }
    };

    return (
        <Box sx={{ paddingBottom: '50px' }}>
            <CustomBreadcrumbs
                breadcrumbs={[
                    { url: '/malls', text: 'SC一覧' },
                    { url: null, text: `SC新規登録` }
                ]}
            />
            <Create title="SC登録" {...props}>
                <MallForm
                    currentUser={currentUser}
                    validate={validateMallForm}
                    isRequired={true}
                    onSubmit={handleSubmit}
                />
            </Create>
        </Box>
    );
};

export const MallForm = (props: any) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();

    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState();
    const [adminData, setAdminData] = React.useState<any>();
    const [firstSelectionCapacityBasis, setSelectionCapacity] = React.useState<number | null>(null);
    const [numberOfTenants, setNumberOfTenants] = React.useState<number | null>(null);

    React.useEffect(() => {
        if (!record) {
            setAdminData(null);
            setLoading(false);
            return;
        }
        dataProvider
            .getOne('admins', { id: record.adminId })
            .then(({ data }: any) => {
                setAdminData(data);
                setLoading(false);
            })
            .catch((error: any) => {
                setError(error);
                setLoading(false);
            });
    }, []);

    if (loading) return <Loading />;
    if (error) return <p>ERROR</p>;

    const OnlySaveToolbar = () => (
        <Toolbar>
            <SaveButton label="保存" />
        </Toolbar>
    );

    return (
        // <SimpleForm validate={props.validate} toolbar={props.toolbar} onSubmit={props.onSubmit} >
        <SimpleForm validate={props.validate} toolbar={<OnlySaveToolbar />} onSubmit={props.onSubmit}>
            <TextInput label="SC番号" source="id" fullWidth disabled isRequired />
            <TextInput label="SC名" source="mallName" fullWidth isRequired />
            <TextInput label="SC名（カナ）" source="mallNameKana" fullWidth isRequired />
            <ReferenceInput source="areaId" reference="areas">
                <SelectInput
                    label="所属地区"
                    optionText="areaName"
                    isRequired
                    fullWidth
                    disabled={props.currentUser.areaId ? true : false}
                    defaultValue={props.currentUser.areaId ? props.currentUser.areaId : undefined}
                />
            </ReferenceInput>
            <TextInput
                label="管理ログインId"
                source="adminLoginId"
                defaultValue={adminData ? adminData.loginId : null}
                fullWidth
                isRequired
                disabled={props.disabled}
            />
            <PasswordInput label="パスワード" source="password" isRequired={props.isRequired} />
            <PasswordInput label="パスワード（確認）" source="passwordConfirm" isRequired={props.isRequired} />
            <TextInput label="同友店会ID" source="loginId" isRequired />
            <NumberInput
                label="所属テナント数"
                source="numberOfTenants"
                fullWidth
                onChange={(e) => {
                    setNumberOfTenants(e.target.value);
                }}
                isRequired
            />
            <DateTimeInput label="作成日時" source="createdAt" disabled isRequired />
            <DateTimeInput label="更新日時" source="updatedAt" disabled isRequired />
        </SimpleForm>
    );
};
