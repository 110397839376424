import imageCompression from 'browser-image-compression';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useEffect, useRef } from 'react';
import { storage } from '../firebase';

export function usePrevious(value: any) {
    const ref = useRef(null);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function formatDate(date: any, format: string) {
    format = format.replace(/yyyy/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
    return format;
}

export function getUUID(myStrong?: any) {
    let strong = 1000;
    if (myStrong) strong = myStrong;
    return new Date().getTime().toString(16) + Math.floor(strong * Math.random()).toString(16);
}

// contestのquestionsのループ内で使用。postのanswerに対応する文字列をquestionsから取得する。
export function getAnswerString(question: any, questionIndex: number, answers: any): () => string {
    const answerString =
        // 2:セレクトボックス、4:ラジオボックスの設問の場合
        [2, 4].includes(question.type)
            ? question.options[answers[questionIndex].answer].optionText
            : // 3:チェックボックスの設問の場合
            [3].includes(question.type)
            ? answers[questionIndex].answer.map((ans: any, i: number) => {
                  return answers[questionIndex].answer.length !== i + 1
                      ? question.options[ans].optionText + ', '
                      : question.options[ans].optionText;
              })
            : // 0:テキスト、1:テキストエリアの設問の場合
              answers[questionIndex].answer;
    return answerString;
}

// 画像のアップロード関数
export async function uploadImageToFirebaseStorage(
    file: any,
    areaId: string | number | null | undefined,
    type: 'contests' | 'posts' | 'recommends'
) {
    const filename = getUUID();
    // areaIdは、0 or null どちらの場合もallになる
    areaId = !areaId ? 'all' : areaId;

    // オリジナル画像のアップロード
    if (type === 'posts') {
        const optionsOriginal = {
            maxSizeMB: 5, // 最大ファイルサイズ
            maxWidthOrHeight: 4000 // 最大画像幅もしくは高さ
        };
        const fileOriginal = await imageCompression(file, optionsOriginal);
        const storageRefOriginal = ref(storage, areaId + '/' + type + '/images/' + filename + '__original');
        await uploadBytes(storageRefOriginal, fileOriginal).then((snapshot) => {
            console.log('Uploaded a original image!');
        });
    }

    // 圧縮画像のアップロード
    const optionsCompressed = {
        maxSizeMB: 0.1, // 最大ファイルサイズ
        maxWidthOrHeight: 2000 // 最大画像幅もしくは高さ
    };
    const fileCompressed = await imageCompression(file, optionsCompressed);
    const storageRefCompressed = ref(storage, areaId + '/' + type + '/images/' + filename + '__compressed');
    await uploadBytes(storageRefCompressed, fileCompressed).then((snapshot) => {
        console.log('Uploaded a compressed image!');
    });

    // 圧縮の画像urlをDBに格納する
    const fileUrl = await getDownloadURL(storageRefCompressed);

    return fileUrl;
}

// 動画のアップロード関数
export async function uploadVideoToFirebaseStorage(file: any, areaId: number, type: 'posts') {
    const filename = getUUID();

    const storageRefOriginal = ref(storage, areaId + '/' + type + '/videos/' + filename);
    await uploadBytes(storageRefOriginal, file).then((snapshot) => {
        console.log('Uploaded a original video!');
    });

    // 動画urlをDBに格納する（圧縮はfunctionsで行う）
    const fileUrl = await getDownloadURL(storageRefOriginal);

    return fileUrl;
}

// 数字をアルファベットに変える
export function numberToAlphabet(n: number) {
    const radix = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    const digit = radix.length;
    return n.toString(digit).replace(/./g, function (s) {
        return radix[parseInt(s, digit)];
    });
}

/*
 * １次審査通過数の計算（frontend-adminとfunctionsで同じである必要がある）
 */
export const calculateFirstSelectionCapacity = (
    fixedFirstSelectionCapacityBasis: number,
    fixedNumberOfTenants: number,
    postsTotal: number,
    firstSelectionCapacityBasisUpper: number | null | undefined,
    postIndexIncreaseRatio: number | null | undefined,
    increaseStartRatio: number | null | undefined,
    fixedFirstSelectionCapacity: number | null | undefined
): number => {
    // 通常の枠を計算（任意の通過数が設定されている場合はそちらを優先）
    const initialCapacity = fixedFirstSelectionCapacity
        ? fixedFirstSelectionCapacity
        : Math.ceil(fixedNumberOfTenants / fixedFirstSelectionCapacityBasis);
    // 投稿指数を計算
    const postIndex = Math.round((postsTotal / fixedNumberOfTenants) * 1000) / 1000;
    // 上限の枠を計算（店舗数/5）
    const upperLimit = Math.ceil(fixedNumberOfTenants / Number(firstSelectionCapacityBasisUpper));
    // 投稿指数から一次審査通過枠の増加分を計算
    const lowestPostIndex = increaseStartRatio;
    const ratio = postIndexIncreaseRatio;
    const addCapacity =
        postIndex < Number(lowestPostIndex) ? 0 : 1 + (postIndex - Number(lowestPostIndex)) / Number(ratio);
    const calculatedCapacity = initialCapacity + addCapacity;
    // 上限を設定
    const finalValue = calculatedCapacity >= upperLimit ? upperLimit : calculatedCapacity;

    return finalValue;
};

/*
 * バリデーションエラーオブジェクトを抽出する関数
 */
export const getValidationErrors = (error: any): Record<string, any> | null => {
    if (error.response && error.response.data?.errors) {
        // エラーレスポンスからバリデーションエラーを抽出
        console.log('Validation Errors:', error.response.data.errors);
        return error.response.data.errors;
    }
    return null;
};
