import * as React from 'react';
import { useForm, useFormState, useFormContext, useWatch, useFieldArray, Controller } from 'react-hook-form';
import { Box, Button, Typography, useMediaQuery, TextField as MuiTextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    DateTimeInput,
    PasswordInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    useRecordContext,
    Loading,
    useGetIdentity,
    useCreate,
    useRedirect,
    useUpdate,
    useNotify,
    Toolbar,
    SaveButton,
    DeleteButton,
    DeleteWithConfirmButton
} from 'react-admin';
import { formatDate, getValidationErrors, uploadImageToFirebaseStorage } from '../utils/util';
import { Consts } from '../consts/Const';
import { display } from '@mui/system';
import { useState } from 'react';
import { AddAlarmSharp, OpenInNewOffOutlined } from '@mui/icons-material';
import { CustomFormTab, TabBasic } from './RecommendFormComponents';
import Error from '../Error';
import CustomBreadcrumbs from '../layout/Breadcrumbs';

type FormData = {
    title: string;
    subtitle: string;
    description: string;
    areaId: number;
    imageUrl: string;
    publishStart: Date;
    publishEnd: Date;
    applicationStart: Date;
    applicationEnd: Date;
    questions: [
        {
            text: string;
            type: number;
            options: Array<any>;
        }
    ];
};

const ContestTitle = ({ record }: any) => {
    return <span>Contest {record ? `"${record.title}"` : ''}</span>;
};

const PostEditToolbar = (props: any) => {
    const { isSubmitting, ...rest } = props;
    const redirect = useRedirect();
    const notify = useNotify();
    return (
        <Toolbar {...rest} sx={{ justifyContent: 'space-between' }}>
            <SaveButton disabled={isSubmitting} label="保存" />
            <DeleteWithConfirmButton
                confirmContent="おすすめコンテンツを削除してもよろしいですか？"
                confirmTitle="削除確認"
            />
            {/* <DeleteButton/> */}
            {/* <SaveButton
                label="post.action.save_and_add"
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.created', {
                            type: 'info',
                            messageArgs: { smart_count: 1 },
                        });
                        redirect(false);
                    }}
                }
                type="button"
                variant="text"
            /> */}
        </Toolbar>
    );
};

const RecommendEditContent = ({ currentUser, onSubmit, isSubmitting }: any) => {
    const record = useRecordContext();

    if (!record) return <Loading />;

    // 権限別調整
    let isNotPagePermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            isNotPagePermitted = record.areaId !== currentUser.areaId;
            break;
        case Consts.AUTORITY.mall:
            isNotPagePermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPagePermitted = true;
            break;
        default:
            break;
    }

    if (isNotPagePermitted) return <Error type="permission" />;

    return (
        <CustomFormTab
            onSubmit={onSubmit}
            currentUser={currentUser}
            toolbar={<PostEditToolbar isSubmitting={isSubmitting} />}
        />
    );
};

export const RecommendEdit = () => {
    const { identity: currentUser, isLoading } = useGetIdentity();
    const [update] = useUpdate();
    const redirect = useRedirect();
    const notify = useNotify();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async (data: any) => {
        setIsSubmitting(true);
        // バリデーションのみ、imageUrlは、バリデーションのため一時的に、rawFileのファイル名を入れておく。
        console.log(data.id);
        try {
            await update(
                'recommends',
                {
                    id: data.id,
                    data: {
                        isValidationOnly: true,
                        ...data,
                        imageUrl:
                            typeof data.imageUrl === 'string'
                                ? data.imageUrl
                                : data.imageUrl
                                ? data.imageUrl.rawFile.name
                                : null
                    }
                },
                { returnPromise: true }
            );
        } catch (error: any) {
            setIsSubmitting(false);
            const validationErrors = getValidationErrors(error);
            return validationErrors;
        }

        // 画像のアップロード処理
        try {
            if (data.imageUrl && typeof data.imageUrl !== 'string') {
                data.imageUrl = await uploadImageToFirebaseStorage(data.imageUrl.rawFile, data.areaId, 'recommends');
            }
        } catch (error: any) {
            setIsSubmitting(false);
            return {
                imageUrl: '画像のアップロードに失敗しました。適切な画像を選択し直してください。'
            };
        }

        // formの送信
        try {
            await update('recommends', { id: data.id, data: data }, { returnPromise: true });
            notify('resources.contests.notification.updated_success', { type: 'success' });
            redirect('/recommends');
        } catch (error: any) {
            setIsSubmitting(false);
            const validationErrors = getValidationErrors(error);
            return validationErrors;
        }

        setIsSubmitting(false);
    };

    if (isLoading) return <Loading />;

    return (
        <>
            <CustomBreadcrumbs
                breadcrumbs={[
                    { url: '/recommends', text: 'おすすめコンテンツ一覧' },
                    { url: null, text: `おすすめコンテンツ編集` }
                ]}
            />
            <Edit
                title={'おすすめコンテンツ編集'}
                sx={{
                    padding: {
                        // xs: '20px 10px',
                        sm: '0px 0px 20px'
                    }
                }}
            >
                <RecommendEditContent currentUser={currentUser} onSubmit={onSubmit} isSubmitting={isSubmitting} />
            </Edit>
        </>
    );
};
