import * as React from 'react';
import { useForm, useFormState, useFormContext, useWatch, useFieldArray, Controller } from 'react-hook-form';
import { Box, Button, Typography, useMediaQuery, TextField as MuiTextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    DateTimeInput,
    PasswordInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    useRecordContext,
    Loading,
    useGetIdentity,
    useCreate,
    useRedirect,
    useNotify,
    Toolbar,
    SaveButton
} from 'react-admin';
import imageCompression from 'browser-image-compression';
import LinkToRelatedPosts from './LinkToRelatedPosts';
import { formatDate, getUUID, getValidationErrors, uploadImageToFirebaseStorage } from '../utils/util';
import { Consts } from '../consts/Const';
import { display } from '@mui/system';
import { useState } from 'react';
import { AddAlarmSharp, ConstructionOutlined, OpenInNewOffOutlined, RecentActorsOutlined } from '@mui/icons-material';
import { CustomFormTab, TabBasic, TabMallSelections, TabQuestions, TabScoringItems } from './ContestFormComponents';
import Error from '../Error';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../firebase';
import CustomBreadcrumbs from '../layout/Breadcrumbs';

const ContestTitle = ({ record }: any) => {
    return <span>Contest {record ? `"${record.title}"` : ''}</span>;
};

type FormData = {
    title: string;
    subtitle: string;
    description: string;
    areaId: number;
    imageUrl: string;
    publishStart: Date;
    publishEnd: Date;
    applicationStart: Date;
    applicationEnd: Date;
    questions: [
        {
            text: string;
            type: number;
            options: Array<any>;
        }
    ];
};

const PostCreateToolbar = (props: any) => {
    const { isSubmitting, ...rest } = props;
    const redirect = useRedirect();
    const notify = useNotify();
    return (
        <Toolbar {...rest}>
            <SaveButton disabled={isSubmitting} label="保存" />
            {/* <SaveButton
                label="post.action.save_and_add"
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.created', {
                            type: 'info',
                            messageArgs: { smart_count: 1 },
                        });
                        redirect(false);
                    }}
                }
                type="button"
                variant="text"
            /> */}
        </Toolbar>
    );
};

const ContestCreateContent = ({ currentUser, onSubmit, isSubmitting, validate }: any) => {
    // 権限別調整
    let isNotPermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            break;
        case Consts.AUTORITY.mall:
            break;
        case Consts.AUTORITY.maker:
            isNotPermitted = true;
            break;
        default:
            break;
    }

    if (isNotPermitted) return <Error type="permission" />;

    return (
        <CustomFormTab
            validate={validate}
            onSubmit={onSubmit}
            currentUser={currentUser}
            toolbar={<PostCreateToolbar isSubmitting={isSubmitting} />}
        />
    );
};

export const ContestCreate = () => {
    const { identity: currentUser, isLoading } = useGetIdentity();
    const [create] = useCreate();
    const redirect = useRedirect();
    const notify = useNotify();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const validateContestsForm = async (values: any) => {
        const errors: any = {};
        if (!values.title) {
            errors.title = '必須です';
        }
        if (!values.subtitle) {
            errors.subtitle = '必須です';
        }
        if (values.contestType === '') {
            errors.contestType = '必須です';
        }
        if (!values.maxSelectionNumber) {
            errors.maxSelectionNumber = '必須です';
        }
        if (!values.areaId) {
            errors.areaId = '必須です';
        }
        if (!values.imageUrl) {
            errors.imageUrl = '必須です';
        }
        if (!values.applicationStart) {
            errors.applicationStart = '必須です';
        }
        if (!values.applicationEnd) {
            errors.applicationEnd = '必須です';
        }
        if (!values.firstSelectionCapacityBasis) {
            errors.firstSelectionCapacityBasis = '必須です';
        }
        if (!values.mallSelections?.length) {
            errors.mallSelections = 'SC審査設定に不備があります';
        }
        if (!values.questions?.length) {
            errors.questions = '設問を1つ以上を入力してください';
        }
        if (!values.scoringItems?.length) {
            errors.scoringItems = '採点項目を1つ以上を入力してください';
        }
        return errors;
    };

    const onSubmit = async (data: any) => {
        setIsSubmitting(true);
        // バリデーションのみ、imageUrlは、バリデーションのため一時的に、rawFileのファイル名を入れておく。
        try {
            await create(
                'contests',
                {
                    data: {
                        isValidationOnly: true,
                        ...data,
                        imageUrl: data.imageUrl ? data.imageUrl.rawFile.name : null
                    }
                },
                { returnPromise: true }
            );
        } catch (error: any) {
            setIsSubmitting(false);
            const validationErrors = getValidationErrors(error);
            return validationErrors;
        }

        // 画像のアップロード処理
        try {
            if (data.imageUrl && data.areaId) {
                data.imageUrl = await uploadImageToFirebaseStorage(data.imageUrl.rawFile, data.areaId, 'contests');
            }
        } catch (error: any) {
            setIsSubmitting(false);
            return {
                imageUrl: '画像のアップロードに失敗しました。適切な画像を選択し直してください。'
            };
        }

        // formの送信
        try {
            await create('contests', { data: data }, { returnPromise: true });
            notify('resources.contests.notification.created_success', { type: 'success' });
            redirect('/contests');
        } catch (error: any) {
            setIsSubmitting(false);
            const validationErrors = getValidationErrors(error);
            return validationErrors;
        }

        setIsSubmitting(false);
    };

    if (isLoading) return <Loading />;

    return (
        <Box sx={{ paddingBottom: '50px' }}>
            <CustomBreadcrumbs
                breadcrumbs={[
                    { url: '/contests', text: 'コンテスト一覧' },
                    { url: null, text: `コンテスト新規登録` }
                ]}
            />
            <Create
                sx={{
                    padding: {
                        // xs: '20px 10px',
                        sm: '0px 0px 20px'
                    }
                }}
            >
                <ContestCreateContent
                    validate={validateContestsForm}
                    currentUser={currentUser}
                    onSubmit={onSubmit}
                    isSubmitting={isSubmitting}
                />
            </Create>
        </Box>
    );
};
